
.App {
  position: relative;
}

body {
  margin: 0;
}

.cta {
  position: absolute;
  left: 885px;
  top: 193px;

  border: none;
  margin: 0;
  padding: 0;
  width: 300px;
  height: 45px;
  overflow-y: hidden;
  overflow-x: hidden;
}
